const routes = require('next-routes')();

routes
  .add('index', '/', 'index')
  .add('results', '/results/:listingType(partners|centres)/:type/:query', 'results')
  .add('serviceProviderProfile', '/:profileType(partners|centres)/:id', 'serviceProviderProfile')
  .add('book-appointment', '/book-appointment', 'bookingPage')
  .add('booking-confirmation', '/booking-confirmation', 'checkoutPage')
  .add('embed/clinic-page', '/embed/:profileType(partners|centres)/:id', 'embed/clinicPage')
  .add('embed/book-appointment', '/embed/book-appointment', 'embed/bookingPage')
  .add('embed/booking-confirmation', '/embed/booking-confirmation', 'embed/checkoutPage')
  .add('embed/modal/book-appointment', '/embed/modal/book-appointment', 'embed/modal/bookingPage')
  .add('embed/modal/booking-confirmation', '/embed/modal/booking-confirmation', 'embed/modal/checkoutPage')
  .add('reviews', '/reviews/:serviceprovider', 'reviews')
  .add('redirect', '/redirect', 'redirect')
  .add('info/terms', '/info/terms', '/info/terms')
  .add('info/bookingterms', '/info/bookingterms', '/info/bookingterms')
  .add('info/privacy', '/info/privacy', '/info/privacy')
  .add('dashboard/dashboard', '/dashboard', '/dashboard/dashboard')
  .add('dashboard/favorites', '/dashboard/favorites', '/dashboard/favorites')
  .add('dashboard/invoice-list', '/dashboard/invoice-list', '/dashboard/invoicing-list')
  .add('dashboard/invoice-details', '/dashboard/invoice-details/:invoiceId', '/dashboard/invoicing-view')
  .add('dashboard/settings', '/dashboard/settings', '/dashboard/settings')
  .add('dashboard/faqPage', '/dashboard/faqPage', '/dashboard/faqPage')
  .add('dashboard/contact', '/dashboard/contact', '/dashboard/contact')
  .add('dashboard/appointment-list', '/dashboard/appointment-list', '/dashboard/appointment-list')
  .add('users/welcome', '/users/welcome', '/users/welcome')
  .add('users/signin', '/users/signin', '/users/signin')
  .add('users/signup', '/users/signup', '/users/signup')
  .add('users/signup-password', '/users/signup-password', '/users/signup-password')
  .add('users/forgot-password', '/users/forgot-password', '/users/forgot-password')
  .add('users/reset-password', '/users/reset-password/:hash', '/users/reset-password')
  .add('users/verification-code', '/users/verification-code', '/users/verification-code')
  .add('widgets/minimal', '/widgets/minimal', '/widgets/minimal')
  .add('widgets/custom', '/widgets/custom', '/widgets/custom');
  //.add('widgets/centre', '/widgets/centre', '/widgets/centre');

module.exports = routes;
